#logo-container {
    display: grid;
    grid-template-columns: 0.1fr 1fr;
    align-items: center;
    margin: 0.75rem 0;
}

#logo-title {
    color: #727176;
    font-weight: bold;
    margin: 0;
    margin-right: 0.6rem;
    padding: 0 0 0 0.5rem;
}

#svg-logo {
    height: 50px;
    width: 50px;
}


/* ---- SVG Logo Styles ---- */

.cls-1 {
    fill: #5fbdd6;
}

.cls-2 {
    fill: #f27e25;
}

.cls-3,
.cls-6,
.cls-7,
.cls-8 {
    fill: none;
    stroke: #fff;
    stroke-miterlimit: 10;
}

.cls-3 {
    stroke-linecap: round;
    stroke-width: 2px;
}

.cls-4 {
    fill: #fff;
}

.cls-5 {
    fill: #b53737;
}

.cls-6 {
    stroke-width: 3px;
}

.cls-7 {
    stroke-width: 5px;
}

.cls-8 {
    stroke-width: 8px;
}


/* ---- Very Large Devices, Large Desktops ---- */

@media only screen and (min-width: 1600px) {

}


/* ---- Large Devices, Large Laptops & Normal Desktops ---- */

@media only screen and (min-width: 1025px) and (max-width: 1601px) {

}


/* ---- Medium-Large Devices, Small Laptops & Large Tablets ---- */

@media only screen and (min-width: 769px) and (max-width: 1024px) {}


/* ---- Medium Devices, Tablets ---- */

@media only screen and (min-width: 481px) and (max-width: 768px) {}


/* ---- Small Devices, Medium-Large Phones ---- */

@media only screen and (min-width: 321px) and (max-width: 480px) {}


/* ---- Very Small Devices, Small Phones ---- */

@media only screen and (max-width: 320px) {}
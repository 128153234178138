:root {
  --drop-shadow-color: rgba(0, 0, 0, 0.135216);
}

.navbar-container {
  position: fixed;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  width: 300px;
  height: 95%;
  /* transition: 200ms ease; */
  border-radius: 10px;
  overflow-x: hidden;
  text-align: justify;
}

.section {
  width: 100%;
}

/* .navbar-container > section {
  width: 100%;
} */

.navbar-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar-item {
  width: 100%;
  height: 100%;
}

.navbar-link {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  filter: grayscale(100%) opacity(0.9);
  transition: 300ms;
  color: #727176;
}

.navbar-link:hover {
  text-decoration: none;
}

.navbar-link:hover {
  filter: grayscale(0%) opacity(1);
  color: #7ecfe4;
}

#logo .navbar-item {
  margin-bottom: 7.5%;
}

.navbar-list {
  margin: 2rem 0;
  margin-top: 15%;
}

.navbar-link {
  width: 100%;
  height: 100%;
  flex-direction: row;
  height: 2.5rem;
}

.nav-icon {
  height: 75%;
  width: 20%;
  min-width: 30px;
  min-height: 30px;
  margin-right: 0.5rem;
}

.logo-container {
  margin-left: 0.6rem;
}

.nav-user-information {
  color: #1d9dbf;
}

/* ---- Very Large Devices, Large Desktops ---- */

@media only screen and (min-width: 1441px) {
}

/* ---- Large Devices, Large Laptops & Normal Desktops ---- */

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
}

/* ---- Medium-Large Devices, Small Laptops & Large Tablets ---- */

@media only screen and (min-width: 769px) and (max-width: 1024px) {
}

/* ---- Medium Devices, Tablets ---- */

@media only screen and (min-width: 481px) and (max-width: 768px) {
}

/* ---- Small Devices, Medium-Large Phones ---- */

@media only screen and (min-width: 321px) and (max-width: 480px) {
}

/* ---- Very Small Devices, Small Phones ---- */

@media only screen and (max-width: 320px) {
}

/* @import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Lato";
}

/* Resets the default setting */
.row {
  margin-top: 0;
  width: 100%;
}

/* Font Sizes */
.big-title-size {
  font-size: 36px;
}

.title-size {
  font-size: 22px;
}

.sub-title-size {
  font-size: 18px;
}

.body-text-size {
  font-size: 14px;
}

/* Spacing */
.mrr-20 {
  margin-right: 20px;
}

.mrl-12 {
  margin-left: 12px;
}

.mrl-20 {
  margin-left: 20px;
}

.mrb-12 {
  margin-bottom: 12px;
}

.mrt-12 {
  margin-top: 12px;
}

.mrt-24 {
  margin-top: 24px;
}

.mrb-8 {
  margin-bottom: 8px;
}

.mrb-25 {
  margin-bottom: 25px;
}

.mrb-24 {
  margin-bottom: 24px;
}

.screen-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Font Styles */
.title {
  align-self: center;
  color: #727176;
}

.title-gray {
  color: #727176;
}

.sub-title {
  align-self: center;
  font-weight: 700;
  color: #e3ad84;
}

.body-text {
  align-self: center;
  color: #727176;
}

/* Colors */
.failure {
  color: #e74c3c;
}

.primary-blue {
  color: #5fbdd6;
}
.text-blue {
  color: #116e87;
}
.border-blue {
  border-color: #5fbdd6;
}

.dark-grey {
  color: #727176;
}
/* Icons */
.sub-title-icon {
  width: 30px;
  height: 30px;
  border-radius: 8px;
  background-color: #5fbdd6;
  align-self: center;
}

/* Buttons */
button:focus {
  outline: none !important;
}

.secondary-button {
  height: 50px;
  width: 175px;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #5fbdd6;
  background-color: #5fbdd6;
  color: #ffffff;
  box-shadow: 0px 2px 4px 0px #00000040;
}

.secondary-button:hover {
  background-color: transparent;
  color: #5fbdd6;
}

.text-button {
  padding: 0;
  border: none;
  background: none;
  color: #5fbdd6;
  height: 100%;
  align-items: center;
}

.text-button:hover {
  text-decoration: underline;
}

.edit-button {
  margin-left: auto;
}

.edit-row {
  align-items: center;
}

.cancel-button {
  height: 50px;
  width: 175px;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #5fbdd6;
  background-color: #ffffff;
  color: #5fbdd6;
  box-shadow: 0px 2px 4px 0px #00000040;
}
/* Box Container */
.box-container {
  padding: 24px;
  border-radius: 10px;
  background-color: #ffffff;
  margin-bottom: 25px;
}

.box-leftContainer {
  padding: 24px;
  border-radius: 10px 0px 0px 10px;
  background-color: #ffffff;
  margin-bottom: 25px;
}

.box-rightContainer {
  padding: 24px;
  border-radius: 0px 10px 10px 0px;
  background-color: #ffffff;
  margin-bottom: 25px;
}

/* Stats Card */
.stats-container {
  gap: 12px;
}
.statsCard {
  width: 150px;
  height: 158px;
  background-color: #fafafa;
  box-shadow: 0px 2px 4px 0px #00000040;
  border-radius: 10px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.statsCard.stats-card-short {
  height: initial;
}

.stats-squareStudyContainer {
  display: flex;
}

.stats-title {
  align-self: center;
  color: #5fbdd6;
  margin-left: 8px;
}

div.stats-row.row {
  flex-direction: column;
}

.stats-number {
  color: #727176;
}

.stats-diff {
  margin-left: 12px;
  align-self: end;
  margin-bottom: 8px;
}

.stats-bottomTitle {
  color: #727176;
}

/* Number Card */
.numberCard {
  width: 46%;
  min-width: 150px;
  height: 100px;
  background-color: #fafafa;
  border-radius: 10px;
  margin-bottom: 12px;
  margin-right: 12px;
  padding: 12px;
  box-shadow: 0px 2px 4px 0px #00000040;
}

.number-cardIcon {
  align-self: center;
}

.number-cardDataContainer {
  justify-content: center;
  margin-top: -4px;
}

.number-cardData {
  font-size: 30px;
  font-weight: 700;
  color: #5b5b5b;
  margin-left: 8px;
  align-self: center;
}

.number-cardBottomLabel {
  color: #727176;
}

/* Drawer */
.drawer {
  width: 100%;
  height: 85px;
  display: flex;
  justify-content: space-between;
  background-color: #fafafa;
  border-radius: 10px;
  padding: 12px;
  box-shadow: 0px 2px 4px 0px #00000040;
}

.drawer-container {
  display: flex;
  flex-direction: column;
}

.drawer-desc {
  margin-top: 8px;
}

.drawer-title {
  color: #5fbdd6;
}

.drawer-text {
  color: #727176;
}

/* Icon and Text */

.row.icon-text-row {
  margin-top: 12px;
  align-items: center;
}

.icon-text-stats {
  margin-left: 12px;
}

.icon-text-text {
  margin-left: 12px;
}
/* hd-graphEditContainer */
.hd-graphEditContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.hd-graphContainer {
  width: 82%;
  height: 163px;
  background-color: #fafafa;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;
}

.card-title-text {
  color: #002b59;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
}

.sky-blue-bg {
  background-color: #5fbdd6;
}

.patient-leftGraphContainer {
  width: 250px;
  float: left;
}

.patient-rightGraphContainer {
  flex-grow: 1;
  text-align: center;
  align-items: center;
}

.patient-graphContainer {
  display: flex;
  max-height: 495px;
}

div.sd-loadGraphText {
  vertical-align: center;
  margin-top: 18%;
}

#loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#loader {
  display: block;
  width: 150px;
  height: 150px;
  border: 3px solid transparent;
  border-top-color: #7ecfe4;
  z-index: 1500;
  animation: linespin 2s linear infinite;
}

#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border: 3px solid transparent;
  border-top-color: #3f4450;
  animation: linespin 3s linear infinite;
}

#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border: 3px solid transparent;
  border-top-color: #f27e25;
  animation: linespin 1.5s linear infinite;
}

#loader,
#loader:before,
#loader:after {
  border-radius: 50%;
}

@keyframes linespin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

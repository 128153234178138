.modal {
    
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1000; /* Ensure it's on top of other content */
}

.modal.show {
    display: flex; /* Show when 'show' class is added */
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%; /* Or fixed width, e.g., 500px */
    max-width: 600px; /* Optional max width */
}

.modal-header {
    display: flex;
    justify-content: flex-end;
}

.close-button {
    cursor: pointer;
    border: none;
    background: none;
    font-size: 24px;
}

/* New styles for button container */
.button-container {
    display: flex;
    justify-content: center; /* Center the buttons horizontally */
    gap: 10px; /* Space between the buttons */
    margin-top: 20px; /* Space above the buttons */
}

.img-small {
    max-width: 150px;
    max-height: 150px;
}

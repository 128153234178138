.patientDisplayContainer {
    height: 95%;
    background-color: #ffffff;
    border-radius: 8px;
    display: grid;
    grid-template-rows: 1fr 10fr;
}

.patientTableContainer {
    border-radius: 10px;
}

.MuiDataGrid-windowContainer {
    height: 100%;
}

.MuiDataGrid-main{
    border-radius: 10px;
}

.row-full {
    width: 100vw;
    position: relative;
}

.directory-title {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    margin-left: 2.5%;
}

.filter-title {
    font-style: normal;
    font-weight: 900;
    font-size: 95%;
    line-height: 169%;
    /* or 169% */
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #727176;
    margin-left: 2.5%;
    margin-right: 5%;
}

.filter-label {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    color: #727176;
    margin-right: 1%;
}

.directory-toggle {
    margin-left: auto;
    margin-right: 30px;
}

.pd-cardsContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-content: flex-start;
    justify-content: center;
    overflow-y: scroll;
}

.patientTableContainer {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: "8px";
    display: flex;
    justify-content: center;
}

.patientTableLoadingSpinner {
    height: 40%;
    justify-self: center;
    align-self: center;
}
/* Styling is from biggest screen size to smallest screensize */

/* Resets the default setting */
div.row {
  margin-top: 0;
}

/* CSS Rules for Summary Container */
.sumContainer {
  display: flex;
}

.sumLeftCardsContainer {
  width: 50%;
  flex-grow: 2;
}

.leftSumFlexContainer {
  display: flex;
}

.sumRightCardsContainer {
  width: 250px;
  display: flex;
  flex-direction: column;
}

/* Rules for Study Containers */

.studyMainContainer {
  display: flex;
}

.studyLeftContainer {
  width: 40%;
  flex-grow: 2;
}

.studyRightContainer {
  min-width: 350px;
  flex-grow: 1;
}

.studyIcons {
  color: #1d9dbf;
  fill: #1d9dbf;
}

.hd-graphContainer {
  height: 125px;
  background-color: #fafafa;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;
}

.studyTrendCard {
  width: 200px;
  height: 150px;
  flex-basis: auto;
  margin-top: 12.5px;
  margin-bottom: 10px;
  margin-right: 37.5px;
  background-color: #fafafa;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;
}

.studyLastTrendCard {
  width: 200px;
  height: 150px;
  flex-basis: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #fafafa;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;
}

.graphTitle {
  font-family: "Lato";
  color: #979797;
  font-weight: 600;
  line-height: 19px;
  margin: 10px;
}

.pastWeekTitle {
  font-family: Lato;
  color: #979797;
  font-weight: 600;
  line-height: 19px;
  margin-top: 15px;
}

.studyTopCardContainer {
  display: flex;
  margin: 15px;
  margin-bottom: 10px;
}

.studyBottomCardContainer {
  margin-left: 15px;
}

.studyBlueSquare {
  width: 45px;
  height: 45px;
}

.act-blueSquare {
  width: 32.5px;
  height: 32.5px;
  border-radius: 8px;
  background-color: #5fbdd6;
}

.dh-trendCard {
  width: 95%;
  height: 90%;
  margin-top: 7.5px;
  background-color: #fafafa;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;
}

.act-lastTrendCard {
  width: 200px;
  height: 150px;
  flex-basis: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #fafafa;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px #00000040;
}

.act-topCardContainer {
  display: flex;
  margin-left: 12.5px;
  padding-top: 10px;
}

.act-bottomCardContainer {
  margin-left: 15px;
}

.act-trendCardTitle2 {
  font-style: Open Sans;
  color: #5fbdd6;
  font-weight: 600;
  letter-spacing: 0.75px;
  align-self: center;
  margin-left: 7.5px;
}

.act-trendNumbers2 {
  font-family: "Poppins";
  font-weight: 900;
  color: #727176;
  font-size: 2rem;
}

.act-trendCardBottomLabel2 {
  font-family: Open Sans;
  color: #727176;
  font-weight: 600;
  margin-bottom: 10px;
}
.trendsBlueSquare {
  width: 50px;
  height: 50px;
}

.newStudyModal {
  width: 600px;
  height: 650px;
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.nsModal-content {
  margin-top: 24px;
  margin-left: 12px;
}

.nsModal-container {
  display: flex;
  flex-direction: column;
}

.nsModal-NextButton {
  position: fixed;
  align-items: center;
  justify-content: center;
  bottom: 5%;
  left: 40%;
}

.nsModal-checkboxRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nsModal-customFeaturesContainer {
  width: 90%;
  margin-left: 15px;
}

.nsModal-textInputs {
  width: 100%;
  padding: 10px;
}

.nsModal-bottomTextInputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
}

@media only screen and (min-width: 1201px) and (max-width: 1440px) {
}

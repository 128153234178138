.gradient-button-checkout {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: Lato !important;
  /* border: 1px solid #7ecfe4; */
  border-radius: 5px;
  color: white;
  padding: 0 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: linear-gradient(90deg, #28cdf9 30%, #019bc3 100%);
  text-transform: none !important;
  overflow: hidden;
  transition: opacity 0.5s ease-in-out;
  height: 5.2rem;
}

.gradient-button-checkout::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, #019bc3 30%, #28cdf9 100%);
  border: 1px solid #7ecfe4;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
  z-index: 0;
}

.gradient-button-checkout:hover::before {
  opacity: 0;
}

.gradient-button-checkout:active {
  background: linear-gradient(90deg, #00c2f5 100%, #00a2cd 100%);
  box-shadow: none;
}

.gradient-button-checkout > * {
  position: relative;
  z-index: 1;
}

#homepage-container {
  background: repeating-linear-gradient(#f5f9ff 50%, #cbf4ff 80%, #f5f9ff 90%);
  font-family: "Sansation";
}
.barcode-scanner {
  height: 600px;
}

.home-page-main {
  max-width: 1500px;
  margin: 0 auto;
}

#homepage-content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#homepage-text-container {
  padding: 0 4vw;
  margin-left: 4rem;
  vertical-align: middle;
}

#homepage-feature-list {
  font-weight: 400;
  font-size: 24px;
}

#homepage-feature-list li {
  line-height: 50px;
  list-style: none;
  background-image: url("../img/bullet-point.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 15px;
  margin: 0;
  padding: 6px 0 6px 30px;
  font-family: "Avenir";
}

.homepage-title {
  font-size: 1.8em;
  margin-bottom: 0.5em;
  font-weight: 700;
  color: #1d9dbf;
  font-family: "Sansation";
}

#illustration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 700px;
  margin-top: 6em;
}

footer {
  font-weight: 700;
  font-family: "Open Sans";
}
footer p {
  color: #116e87;
  margin: 0;
  padding: 0.5rem 0;
}

.sub-title {
  font-family: Avenir;
  font-weight: 800;
  color: #002b59;
}
#section-two > .sub-title {
  width: 55%;
  text-align: center;
}

/* ---- Small Devices, Medium-Large Phones ---- */
@media only screen and (max-width: 768px) {
  .home-page-main {
    max-width: 1500px;
    margin: 0 auto 10rem auto;
  }

  #homepage-feature-list li {
    line-height: 30px;
    margin-top: 15px;
  }

  #homepage-container {
    /* height: 100vh; */
    /* background: linear-gradient(180deg, #f6fdff 30%, #caf4ff 70%); */
    font-family: "Sansation";
  }

  #homepage-content-container {
    flex-direction: column;
    padding-top: 2em;
  }

  #homepage-text-container {
    width: 90%;
    padding: 0 8vw;
    margin: 1rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: left;
    font-family: "Sansation";
    padding-left: 0;
  }

  #homepage-title {
    font-size: 1.8em;
  }

  #homepage-feature-list {
    margin-bottom: 1rem;
    font-size: 1.2em;
  }

  #illustration-container {
    width: 90%;
    padding: 0.5em;
    margin: 2em 0.5em 0.5em 0.5em;
    height: initial;
  }

  .barcode-scanner {
    height: 420px;
  }

  #section-two > .sub-title {
    width: 80%;
    margin: 0 auto;
  }
}

#learnmorebutton {
  border: none;
  background-color: white;
  color: #f27e25;
  padding: 1.5vh 4vw;
  border-radius: 3em;
  font-weight: bolder;
  margin: 5vh;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Hide overflow if needed */
}

.image-container img {
  
  height: auto; /* Maintain aspect ratio */
  width: auto; /* Maintain aspect ratio */
  display: block; /* Remove any inline spacing */
}

#howitworks {
  text-align: center;
  color: #f27e25;
  font-size: 4vw;
  margin: 36vw 0 8vw;
}

#onboarding {
  color: #f27e25;
  position: relative;
  top: 4.5vw;
  left: 1vw;
  font-weight: 9000;
  font-size: 6vw;
}

.slideshow {
  z-index: 0;
  position: center;
}

.contentcontainer {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 60vw;
  margin: 0 auto;
}

.iconcontainer {
  display: inline-block;
  width: 20vw;
  height: 20vw;
}

.descriptioncontainer {
  margin-top: 1vw;
  width: 20vw;
  display: inline-block;
}

.imgshadow {
  -webkit-box-shadow: 7px 7px 66px -33px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 7px 7px 66px -33px rgba(0, 0, 0, 0.75);
  box-shadow: 7px 7px 66px -33px rgba(0, 0, 0, 0.75);
}

.nums {
  color: #f27e25;
  position: relative;
  top: -22vw;
  left: -4vw;
  font-weight: bolder;
  font-size: 8vw;
}

.parallax {
  /* The image used */
  background-image: url("../img/stockdoctorimg.jpg");

  /* Set a specific height */
  height: 30vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.colorboxes {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.colorbox {
  flex-grow: 1;
  width: 20vw;
  text-align: center;
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.colorbox img {
  height: 8vw;
  width: 8vw;
  margin-bottom: 3.5vh;
}

.colorbox .warped {
  width: 11vw;
}

#footer-text {
  color: hsl(0, 0%, 0%);
  margin: 0;
  padding: 0.5rem 0;
}

a.black:link {
  color: #0051ff;
  text-decoration: none;
}

a.black:visited {
  color: #0051ff;
  text-decoration: none;
}

a.black:hover {
  color: #0051ff;
  text-decoration: none;
}

a.black:active {
  color: #0051ff;
  text-decoration: none;
}

.title {
  flex-grow: 1;
}

@media screen and (max-width: 640px) {
  #howitworks {
    font-size: 9vw;
  }

  #onboarding {
    font-size: 10vw;
    top: 7vw;
  }

  .pagetitle {
    font-size: x-small;
    margin-bottom: 1rem;
  }

  .line {
    margin: 0;
  }

  .contentcontainer {
    margin-bottom: 3vh;
    margin-top: 3vh;
  }

  .descriptioncontainer {
    width: 50vw;
  }

  .colorboxes {
    flex-direction: column;
  }

  .colorbox img {
    height: 20vw;
    width: 20vw;
  }
  .colorbox .warped {
    height: 20vw;
    width: 25vw;
  }
  .colorbox {
    width: 100vw;
  }
}

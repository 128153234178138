@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
.title-bar {
  .title-text-wrapper {
    .title {
      color: #0195bc;
      font-family: "Open Sans";
      font-weight: 800;
      font-size: 1.1rem;
      text-transform: lowercase;
      letter-spacing: 0.5px;
      margin-bottom: 0.4rem;
    }
    .sub-title {
      font-family: "Poppins";
      color: #727175;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.5rem;
      letter-spacing: 1.5px;
    }
  }
  .appBar {
    background-color: "#ffffff";
    border-radius: "10px";
    box-shadow: "none";
    position: static;
  }

  .toolBarContainer {
    position: relative;
    align-items: center;
    margin-right: 0;
  }

  .searchAvatarContainer {
    display: inline-block;
  }

  .toolBarIconContainer {
    display: inline-block;
    position: absolute;
    right: 0;
  }
}

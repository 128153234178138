#desktop-navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 1.5em;
  padding: 1.5em 0;
}

#desktop-navbar-title-container {
  display: flex;
  align-items: center;
  margin-right: 4rem;
}

#desktop-navbar-title {
  font-family: Poppins;
  display: flex;
  display: inline;
  padding: 0;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: #1d9dbf;
}

#desktop-navbar-logo {
  display: inline-block;
  margin-right: 0.5rem;
  height: 30px;
  width: 30px;
  max-height: 30px;
  max-width: 30px;
}

#desktop-navbar-logo-link:hover {
  cursor: pointer;
  text-decoration: none;
}

#mobile-app-link-container {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 20px;
}

#mobile-navbar-title-container {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  justify-content: space-between;
}

/* Remove? */
#mobile-navbar-title-container > svg {
  color: #008fb5;
}

#signin-btn-responsive {
  font-family: "Lato";
  border: none;
  border-radius: 8px;
  color: #fff;
  background-color: #0195bc;
  font-weight: 700;
  font-size: 14px;
  width: 96px;
  height: 42px;
  text-transform: none;
  size: 16px;
  line-height: 19.2px;
}

#signin-btn-responsive:hover,
#signin-btn-long:hover {
  box-shadow: 0 4px 10px #6cb4c6;
}

#mobile-navbar-container {
  background: linear-gradient(90deg, #f5f9ff 35%, #7ecfe4 100%);
}

#mobile-navbar-menu {
  background-color: white;
}

#mobile-navbar-link-container {
  list-style: none;
  padding: 0;
}

#mobile-navbar-link-container li {
  color: black;
}

#mobile-navbar-signin-btn {
  margin: 1rem 0;
  padding: 0.5rem 2.5rem;
  border: none;
  border-radius: 8px;
  color: white;
  background-color: #01a5d1;
  font-weight: 400;
  font-size: 14px;
}
#mobile-navbar-signin-btn :hover {
  box-shadow: 0 4px 10px #01a5d1;
}

.flex-row {
  display: flex;
  align-items: center;
}

/* ---- Very Large Devices, Large Desktops ---- */
@media only screen and (min-width: 1441px) {
  #mobile-navbar-container {
    display: none;
  }
}

/* ---- Large Devices, Large Laptops & Normal Desktops ---- */
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  #mobile-navbar-container {
    display: none;
  }
}

/* ---- Medium-Large Devices, Small Laptops & Large Tablets ---- */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  #mobile-navbar-container {
    display: none;
  }
}

/* ---- Medium Devices, Tablets ---- */
@media only screen and (min-width: 481px) and (max-width: 768px) {
  #desktop-navbar-container {
    display: none;
  }

  #mobile-navbar-container {
    background: none;
  }

  #mobile-navbar-link-container li {
    font-size: 18px;
    padding: 0.75rem 1rem;
  }
}

/* ---- Small Devices, Medium-Large Phones ---- */
@media only screen and (min-width: 321px) and (max-width: 480px) {
  #desktop-navbar-container {
    display: none;
  }

  #mobile-navbar-container {
    background: none;
  }

  #mobile-navbar-link-container li {
    font-size: 18px;
    padding: 0.75rem 1rem;
  }
}

/* ---- Very Small Devices, Small Phones ---- */
@media only screen and (max-width: 320px) {
  #desktop-navbar-container {
    display: none;
  }

  #mobile-navbar-container {
    background: none;
  }

  #mobile-navbar-link-container li {
    font-size: 16px;
    padding: 0.75rem 1rem;
  }
}

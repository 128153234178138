.feature > div {
  width: 90%;
  margin: 0 auto;
  padding: 0 1rem;

  .big-number {
    color: #5fbdd6;
    font-family: Avenir;
  }
  .title {
    color: #008fb5;
  }
}

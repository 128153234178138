.formContainer {
  display: grid;
  /* grid-row-gap: 1%; */
}

.age-input {
  border: 2px solid #999999;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  color: #5fbdd6;
  text-align: center;
}

div.row {
  margin-left: 0em;
  margin-top: 0.5em;
}

.separate {
  margin-left: 0.75em;
  margin-right: 0.75em;
  margin-top: 0.5em;
}

.date-input {
  border: 2px solid #999999;
  border-radius: 10px;
  width: 100px;
  height: 40px;
  color: #5fbdd6;
  text-align: center;
}

label {
  font-style: "Open Sans";
  color: #727176;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 0.9em;
}

.submitButton {
  background-color: red;
}

/* Remove the date indicator */

.date-input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.separate-date {
  margin-left: 0em;
  margin-right: 6em;
  margin-top: 0.5em;
}

.upgrade-modal {
  border: solid 5px #5fbdd6;

  .modal-title {
    color: #002b59;
  }

  .plan {
    background-color: #f6fdff;
    &.selected {
      background-color: #7ecfe4;
    }
  }
}

.parentContainer {
  width: 100%;
  height: 100%;
}

.studyInformation {
  display: flex;
  width: 100%;
}

.si-mainContainer {
  align-items: center;
  min-width: 375px;
  max-width: 400px;
  flex: 1;
  margin-right: 25px;
}

.po-mainContainer {
  flex: 1;
  min-width: 375px;
}

/* Visualizations */
.visualizationControlContainer {
  height: 100%;
  width: 300px;
}

.sd-graphDyanmicSize {
  display: flex;
  width: 100%;
  background-color: white;
}

.sd-graphContainer {
  width: 100%;
  height: 300px;
}

/* ---- Medium-Large Devices, Small Laptops & Large Tablets ---- */

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .si-mainContainer {
    align-items: center;
    height: 100%;
    flex: 1;
    min-height: 250px;
    margin-right: 0;
    min-width: 100%;
  }

  .po-mainContainer {
    width: 100%;
    min-width: 100%;
  }

  .sd-graphContainer {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 100%;
    background-color: #ffffff;
    border-radius: 8px;
    transition: 200ms ease;
  }
}

/* ---- Medium Devices, Tablets ---- */

@media only screen and (min-width: 481px) and (max-width: 768px) {
}

/* ---- Small Devices, Medium-Large Phones ---- */

@media only screen and (min-width: 321px) and (max-width: 480px) {
}

/* ---- Very Small Devices, Small Phones ---- */

@media only screen and (max-width: 320px) {
}

.invite-patient-modal {
  .patient-list-table {
    width: 100%;
    font-size: 14px;

    .patient-list-header {
      height: 60px;
      font-size: 12px;
      font-weight: 600;
    }

    .patient-list-row {
      display: grid;
      grid-template-columns: 2fr 8fr 3fr 4fr 4fr 1.5fr;
      /* column-gap: 15px; */
      height: 40px;
      border-bottom: 1px solid #c4c4c4;

      > span {
        display: flex;
        padding: 0 10px;
        align-items: center;

        &.b-left {
          border-left: 1px solid #c4c4c4;
        }
        &.b-right {
          border-right: 1px solid #c4c4c4;
        }

        &.row-number,
        &.download {
          justify-content: center;
          cursor: pointer;
          border: none;
        }
      }
    }

    .hide {
      visibility: hidden;
    }
  }
}

.study-leftGraphContainer {
  width: 250px;
}

.study-rightGraphContainer {
  flex-grow: 1;
  display: block;
  > div {
    height: 94%;
  }
}

.study-graphContainer {
  display: flex;
  max-height: 495px;
  gap: 25px;
}

div.sd-loadGraphText {
  vertical-align: center;
  margin-top: 18%;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  font-family: "Lato", Arial, Helvetica, sans-serif;
  background-color: #e5e5e5;
}

#root {
  display: grid;
  grid-template-columns: 1fr 4.5fr;
  grid-template-rows: auto;
  grid-template-areas: "sidebar" "mainContent";
  width: 100%;
  height: 100%;
}

.sidebar {
  /* Assuming you have a class or can assign one to your sidebar */
  position: fixed;
  width: 300px; /* Match the width set in grid-template-columns */
  height: 100vh; /* Full viewport height */
  grid-area: sidebar;
  /* Add other styles for your sidebar here (background, padding, etc.) */
}

.parentContainer {
  z-index: 1;
  width: 100%;
  height: 100%;
}

.mainContainer {
  display: grid;
  width: 100%;
  padding-right: 25px;
  margin-top: 25px;
  grid-template-columns: auto;
  grid-template-areas: "topbar" "pageContent";
  justify-items: stretch;
}

.navbarDiv {
  width: 350px;
  padding: 25px;
}

.patientDirectory {
  grid-template-rows: 1fr 5.4fr;
  grid-row-gap: 0;
  row-gap: 0;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.small-loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}

@media only screen and (min-width: 1441px) {
}

/* ---- Large Devices, Large Laptops & Normal Desktops ---- */

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
}

/* ---- Medium-Large Devices, Small Laptops & Large Tablets ---- */

@media only screen and (min-width: 769px) and (max-width: 1024px) {
}

/* ---- Medium Devices, Tablets ---- */

@media only screen and (min-width: 481px) and (max-width: 768px) {
}

/* ---- Small Devices, Medium-Large Phones ---- */

@media only screen and (min-width: 321px) and (max-width: 480px) {
}

/* ---- Very Small Devices, Small Phones ---- */

@media only screen and (max-width: 320px) {
}
